<!-- 我的车辆 -->
<template>
	<div class="myAddressContent width-fill bg-fff" :class="themeClass" v-loading="pageLoading">
		<div class="rightContent">
			<el-row>
				<!-- <el-col :span="2" class="titleTop">
					<div class="titleBox font-size18 font-weight700">我的车辆</div>
				</el-col> -->
				<div style="display: flex;">
					<div style="flex: 1;" class="titleTop">
						<div class="titleBox font-size18 font-weight700">我的车辆</div>
					</div>
					<div class="titleTop font-size14 margin-t-5" style="text-align: right;">
						<div class="comBtn floatl bg-theme font-color-fff text-center radius2 margin-l-20 margin-t-5"
							@click="addCarBtn">新增车辆</div>
						<div class="comBtn floatl bg-theme font-color-fff text-center radius2 margin-l-10 margin-t-5"
							@click="editbtn">编辑车辆</div>
						<div class="comBtn floatl bg-delete font-color-fff text-center radius2 margin-l-10 margin-t-5"
							@click="deletebtn">删除车辆</div>
						<!-- <div class="comBtn floatl bg-theme font-color-fff text-center radius2 margin-l-20 margin-t-5"
							@click="addCarGroup">新增分组</div> -->
						<div class="comBtn floatl bg-theme font-color-fff text-center radius2 margin-l-10 margin-t-5"
							@click="distribution">分组调整</div>
						<div class="comBtn floatl bg-theme font-color-fff text-center radius2 margin-l-10 margin-t-5"
							@click="vehicleSharing">车辆分享</div>
					</div>
				</div>
				<!-- <el-col :span="22" class="titleTop  font-size14 margin-t-5">
					<div class="comBtn floatl bg-theme font-color-fff text-center radius2 margin-l-20 margin-t-5"
						@click="addCarBtn">新增车辆</div>
					<div class="comBtn floatl bg-theme font-color-fff text-center radius2 margin-l-10 margin-t-5"
						@click="editbtn">编辑车辆</div>
					<div class="comBtn floatl bg-delete font-color-fff text-center radius2 margin-l-10 margin-t-5"
						@click="deletebtn">删除车辆</div>
					<div class="comBtn floatl bg-theme font-color-fff text-center radius2 margin-l-20 margin-t-5"
						@click="addCarGroup">新增分组</div>
					<div class="comBtn floatl bg-theme  font-color-fff text-center radius2 margin-l-20 margin-t-5"
						@click="editbtnGroup">编辑分组</div>
					<div class="comBtn floatl  bg-delete  font-color-fff text-center radius2 margin-l-20 margin-t-5"
						@click="deletebtnGroup">删除分组</div>
				</el-col> -->
			</el-row> 
				<!-- <div class="tab margin-t-13 flex-row margin-b-10">
				<div class="flex-row">
					<div class="tabItem text-center" :class="tabSelect == 0 ? 'bg-fff color-theme border-lr-F2F2F2' : ''"
						@click="tabClick(0)">车辆列表</div>
					<div class="tabItem text-center" :class="tabSelect == 1 ? 'bg-fff color-theme border-lr-F2F2F2' : ''"
						@click="tabClick(1)">分组列表</div>
				</div>
				<div class="tab-right"></div>
			</div> -->
			<!-- 车辆分配弹框 -->
			<springFrame v-if="springFrames" :checkData="checkData" :showModel="springFrames" @change="distributions"></springFrame>
			<ShareGroups v-if="ShareGroups" :checkData="checkData" :showModel="ShareGroups" @change="ShareGroupsChange"></ShareGroups>
		
				<div v-show='tabSelect == 0'>
					<el-row class="margin-t-10 flex-row-align-center">
						<div class="">车辆分组：</div>
						<div>
							<el-select v-model="carToolType" @remove-tag="removeTag" multiple collapse-tags filterable class="selectBox"
								clearable placeholder="请选择" @change="optionsStatusChange">
								<el-option v-for="item in carToolList" :key="item.id" :label="item.fGroupName" :value="item.id"
									:disabled="item.disabled">
								</el-option>
							</el-select>
						</div>
						<div class="inputBox margin-l-10">
							<el-input class="" placeholder="请输入联系电话或车牌号模糊查询" v-model="keyword">
								<i slot="suffix" class="el-input__icon el-icon-search pointer" @click="getCarList"></i>
							</el-input>
						</div>
					</el-row>
					<div v-loading="loading" class="tab-content2">
						<div class="flex-row-center-center" v-if="listData.length == 0">
							<svg-icon icon-class="NoRightsAndInterests" />
						</div>
						<el-table :data="listData" stripe border :row-style="{ height: '36px' }"
							:header-cell-style="{ 'text-align': 'center', background: '#F2F2F2', 'font-weight': '400', color: '#000' }"
							@selection-change="handleSelectionChange" height="400" v-if="listData.length>0">
							<el-table-column type="index" width="50" align="center" label="序号" fixed />
							<el-table-column type="selection" width="50" align="center" fixed />
							
							
							<el-table-column prop="fPlateNumber" label="车牌号" width="100" align="left"
								show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="fTransportToolPlateColor" label="牌照颜色" width="100" align="center"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="fLinkMan" label="联系人" width="auto" align="left" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="fLinkManTelCode" label="联系电话" width="160" align="left"
								show-overflow-tooltip>
							</el-table-column>
							
							<el-table-column prop="fGroupName" label="分组" width="125" align="left"
								show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="fTransportToolType" label="车辆类型" width="100" align="center"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="fMaximumLoad" label="载货量(吨)" width="100" align="right"
								show-overflow-tooltip>
							</el-table-column>
						</el-table>
					</div>
					<div :class="themeClass" class="paging">
						<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
							:currentPage="currentPage" :pagesize="pagesize" :total="total"></pagination>
					</div>
				</div>
				<div v-show='tabSelect == 1'>
					<el-row class="margin-t-10 flex-row-align-center">
						<div class="inputBox margin-l-10" style="width:100%">
							<el-input class="" placeholder="请输入分组名模糊查询" v-model="keyword">
								<i slot="suffix" class="el-input__icon el-icon-search pointer" @click="getCarList"></i>
							</el-input>
						</div>
					</el-row>
					<div v-loading="loading" class="tab-content2">
						<div class="flex-row-center-center" v-if="listgRroupData.length == 0">
							<svg-icon icon-class="NoRightsAndInterests" />
						</div>
						<el-table :data="listgRroupData" stripe border :row-style="{ height: '36px' }"
							:header-cell-style="{ 'text-align': 'center', background: '#F2F2F2', 'font-weight': '400', color: '#000' }"
							@selection-change="handleSelectionGroupChange" height="400" v-if="listgRroupData.length>0">
							<el-table-column type="index" width="50" align="center" label="序号" fixed />
							<el-table-column type="selection" width="50" align="center" fixed />
							<el-table-column prop="fStatusName" label="状态" width="80" align="left"
								show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="fGroupCode" label="分组编码" width="160" align="left"
								show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="fGroup" label="分组名称" width="165" align="left" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="fRemark" label="备注" width="160" align="center" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="fCreator" label="创建人" width="160" align="center"
								show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="fCreateTime" label="创建时间" width="160" align="center"
								show-overflow-tooltip>
							</el-table-column>
						</el-table>	
					</div>
					<div :class="themeClass" class="paging">
						<pagination @handleSizeChange="handleSizeGroupChange"
							@handleCurrentChange="handleCurrentGroupChange" :currentPage="currentGroupPage"
							:pagesize="pageGroupsize" :total="Grouptotal"></pagination>
					</div>
				</div>
		</div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex';
	import pagination from '@/components/pagination/pagination.vue'
	import springFrame from '@/components/springFrame/springFrame.vue'
	import ShareGroups from '@/components/springFrame/ShareGroups.vue'
	
	export default {
		props:{
			showModel : {
				type: Boolean,
				default:false
			},
			flags : {
				// type: Boolean,
				default: false
			}
		},
		data() {
			return {
				tabSelect: 0, //tab选中项
				pageLoading: false,
				loading: false,
				keyword: '',
				carToolType: [],
				carToolList: [],
				oldChooseData :[],
				springFrames : false,
				ShareGroups : false,
				listData: [], //车辆列表表格数据
				checkData: [], // 表格点选数据
				checkGroupData: [], //车辆分组表格的
				timeout: null,
				total: 10, //总数据
				Grouptotal: 10,
				currentPage: 1, //当前页
				currentGroupPage: 1,
				pagesize: 100, //每页显示100条数据
				pageGroupsize: 100,
				fTransportToolID: '', //删除车辆需要的id
				fgroupid: '',
				listgRroupData: []
			}

		},
		computed: {
			current: {
				get() {
					return this.currentPage
				},
				set() {}
			},
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		components: {
			pagination,
			springFrame,
			ShareGroups
		},
		mounted() {
			this.getTransportToolType();
			this.getList()
		},
		methods: {
			removeTag(val){
				console.log(val)
				if(val === '全部'){
					this.carToolType = []
				}
			},
			// tab切换
			tabClick(val) {
				this.tabSelect = val;
				if (val == 1) {
					this.getGroupList()
				}
				if (val == 0) {
					this.getList()
				}
			},

			//状态是否全选
			optionsStatusChange(val) {
				console.log(val)
				this.selectAll(val)
			},
			
			
			selectAll(val){
				const allValues = this.carToolList.map(item => {
					return item.id;
				}); 
				// 用来储存上一次选择的值，可进行对比
				const oldVal = this.oldChooseData.length > 0 ? this.oldChooseData : [];
						 
				  // 若选择全部
				  if (val.includes('全部')) {
					this.carToolType = allValues;
				  }
			 
				  // 取消全部选中， 上次有， 当前没有， 表示取消全选
				  if (oldVal.includes('全部') && !val.includes('全部')) {
					this.carToolType = [];
				  }
			 
				  // 点击非全部选中，需要排除全部选中 以及 当前点击的选项
				  // 新老数据都有全部选中
				  if (oldVal.includes('全部') && val.includes('全部')) {
					const index = val.indexOf('全部');
					val.splice(index, 1); // 排除全选选项
					this.carToolType = val;
				  }
			 
				  // 全选未选，但是其他选项都全部选上了，则全选选上
				  if (!oldVal.includes('全部') && !val.includes('全部')) {
					if (val.length === allValues.length - 1) {
					  this.carToolType = ['全部'].concat(val);
					}
				  }
				  console.log(this.carToolType)
				  // 储存当前选择的最后结果 作为下次的老数据
				  this.oldChooseData = this.carToolType
			},
			
			//回车时查询
			getCarList() {
				this.getList()
			},
			//查询所有车辆的分组类别（原为运输类型）
			getTransportToolType() {
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/get-list-groupName', {}).then(
					result => {
						// this.carToolList = []
						// this.carToolType = []
						let carType = [];
						let carGroupType = [];
						carType.push({"id":"全部","fGroupName": "全部 ("+result.obj.fSumcount+")"})
						carGroupType.push("全部")
						result.obj.reslist.forEach(function(item) {
							carType.push({"id":item.value,"fGroupName": item.key})
							carGroupType.push(item.value)
						})		
						this.carToolList = carType;	
						this.carToolType = carGroupType
					},
					rej => {}
				);
			},
			// 点击行触发，选中或不选中复选框
			handleRowClick(row, column, event) {},
			// 选择
			handleSelectionChange(val) {
				this.checkData = val;
				if (this.checkData && this.checkData.length > 0) {
					this.fTransportToolID = this.checkData[0].fTransportToolID;
				}
			},
			//车辆分组数据的选中
			handleSelectionGroupChange(val) {
				this.checkGroupData = val;
				if (this.checkGroupData && this.checkGroupData.length > 0) {
					this.fgroupid = this.checkGroupData[0].fGroupID;
				}
			},
			//查询数据列表
			getList() {
				this.pageLoading = true;
				if(this.carToolType == '全部'){
					this.carToolType = ''
				}
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/get-list-by-groupName', {
					maxResultCount: this.pagesize, //每页显示10条
					skipCount: (this.currentPage - 1) * this.pagesize, //当前页显示数据（跳过多少条数据）
					fGroupName: this.carToolType ? this.carToolType : '',
					filter: this.keyword, //模糊查询
					sorting: ''
				}).then(
					result => {
						console.log(result, "获取车辆信息")
						this.pageLoading = false;
						this.listData = result.obj.items;
						this.total = result.obj.totalCount;
					},
					rej => {
						this.pageLoading = false;
					}
				);
			},
			//查询车辆分组的列表
			getGroupList() {
				this.pageLoading = true;
				this.ApiRequestPost('api/mall/ebcustomer/transport-tool-group/get-list', {
					maxResultCount: this.pagesize, //每页显示10条
					skipCount: (this.currentPage - 1) * this.pagesize, //当前页显示数据（跳过多少条数据）
					filter: this.keyword, //模糊查询
					sorting: '',
				}).then(
					result => {
						console.log(result, "获取车辆分组信息")
						this.pageLoading = false;
						if (result.obj.items && result.obj.items.length > 0) {
							for (let item of result.obj.items) {
								if (item.fCreateTime) {
									item.fCreateTime = this.getTime(item.fCreateTime)
								}

							}
						}
						this.listgRroupData = result.obj.items;
						this.total = result.obj.totalCount;
					},
					rej => {
						this.pageLoading = false;
					}
				);
			},
			/* 弹窗的编辑方法*/
			editbtn() {
				if (this.checkData.length == 0) {
					this.$message.info("请选择操作数据")
					return;
				}
				if (this.checkData.length > 1) {
					this.$message.info("不支持批量编辑")
					return;
				}
				// this.editVisible = true
				this.$router.push({
					path: '/PersonalContent/NewVehicle',
					query: {
						pageType: "编辑车辆信息",
						carToolID: this.fTransportToolID
					},
				});
			},
			//编辑分组
			editbtnGroup() {
				if (this.checkGroupData.length == 0) {
					this.$message.info("请选择操作数据")
					return;
				}
				if (this.checkGroupData.length > 1) {
					this.$message.info("不支持批量编辑")
					return;
				}
				this.$router.push({
					path: '/PersonalContent/NewGroup',
					query: {
						pageType: "编辑车辆分组",
						carToolID: this.fgroupid
					},
				});
			},
			//删除数据
			deletebtn() {
				if (this.checkData.length == 0) {
					this.$message.info("请选择操作数据")
					return;
				}
				let arrDelet = [];
				this.checkData.forEach((item) => {
					arrDelet.push({
						"fTransportToolID": item.fTransportToolID,
						"frv": item.frv,
						"rowStatus": 8,
					})
				})
				this.$confirm('是否确认删除?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.ApiRequestPost('/api/mall/ebcustomer/transporttool/delete-list', arrDelet).then(
							result => {
								this.getList()
								this.getTransportToolType()
							},
							error => {}
						)
					}).catch(() => {})
			},
			//删除车辆分组的数据
			deletebtnGroup() {
				if (this.checkGroupData.length == 0) {
					this.$message.info("请选择操作数据")
					return;
				}
				let arraydata = []
				for (let item of this.checkGroupData) {
					arraydata.push({
						fGroupID: item.fGroupID,
						frv: item.frv,
						rowStatus: item.rowStatus
					})
				}
				this.$confirm('是否确认删除?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.ApiRequestPost('/api/mall/ebcustomer/transport-tool-group/delete-list', arraydata).then(
							result => {
								this.getGroupList()
							},
							error => {}
						)
					}).catch(() => {})
			},
			addCarBtn() {
				this.$router.push({
					path: '/PersonalContent/NewVehicle',
					query: {
						pageType: "新增车辆信息"
					},
				});
			},
			addCarGroup() {
				this.$router.push({
					path: '/PersonalContent/NewGroup',
					query: {
						pageType: "新增车辆分组"
					},
				});
			},
			distributions(val,flags){
				this.springFrames = val;
				if(!this.showModel && !flags){
					this.$message.info("分配成功")
					this.getList()
					this.getTransportToolType()
					return;
				}else{
					this.getList()
					this.getTransportToolType()
					return;
				}
			},
			distribution(){
				console.log(this.showModel)
				if(this.checkData.length < 1 || this.showModel){
					this.$message.info("请选择需要分配的车辆")
					return;
				}
				this.springFrames = true
				// this.springFrames = true
				console.log(this.checkData)
			},
			vehicleSharing(){
				console.log(11)
				this.ShareGroups = true
			},
			ShareGroupsChange(val,flags){
				this.ShareGroups = val;
			},
			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
			},
			//车辆分组表格
			handleSizeGroupChange() {

			},
			handleCurrentGroupChange(val) {
				this.currentGroupPage = val;
				this.getGroupList();
			},

			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		},
		watch: {
			carToolType(curVal, oldVal) {
				if (curVal == '') {
					this.carToolType = 0;
				}
				this.getList();
			}
		}
		
	}
</script>
<style lang="scss" scoped>
	.bg-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.tab-content2 {
		margin-top: 10px;
		width: 100%;
		// height: 400px;
		// overflow-y: scroll;
		// background-color: red;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.margin-l-20 {
		margin-left: 20px;
	}

	.floatl {
		float: left;
	}

	.floatr {
		float: right;
	}

	.bg-delete {
		background-color: #FF4949;
	}

	::v-deep .el-checkbox__inner {
		border-radius: 0 !important;
		// background-color: red;
	}

	.paging {
		text-align: right;
	}

	.myAddressContent {
		display: flex;
		flex-direction: column;
		padding: 0px 14px;
		margin-top: 10px;
		height: calc(100% - 10px);
		box-sizing: border-box;

	}

	.rightContent {
		/* width: 915px; */
		height: 100%;
	}


	.selectBox {
		width: 200px;
	}

	.inputBox {
		width: calc(100% - 280px);
		width: -webkit-calc(100% - 280px);
	}

	.margin-l-auto {
		margin-left: auto;
	}

	.margin-t-5 {
		margin-top: 5px;
	}

	/* 公用btn */
	.comBtn {
		width: 110px;
		height: 30px;
		line-height: 30px;
		cursor: pointer;
	}

	.titleBox {
		height: 40px;
		line-height: 37px;
	}

	::v-deep .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	::v-deep .el-input__icon {
		line-height: 32px;
	}

	.tabCont {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 15px;
	}

	.carInfor {
		width: calc(50% - 9px);
		width: -webkit-calc(50% - 9px);
		width: -moz-calc(50% - 9px);
		box-sizing: border-box;
		display: inline-block;
		background-color: #fff;
		padding: 15px 16px;
		border-radius: 10px;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
		margin-bottom: 15px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.carnamespan {
		min-width: 67px;
		margin-right: 10px;
	}

	.carname {
		color: #000;
		font-weight: bold;
		font-size: 14px;
	}

	.carname span,
	.weight {
		display: block;
	}

	.carname span {
		margin-right: 10px;
		vertical-align: middle;
	}

	.weight {
		font-size: 13px;
		color: #666;
	}

	.editicon .u-icon:last-child {
		margin-left: 10px;
		margin-right: -2.5px;
	}

	.checkcar {
		margin-left: 40px;
		position: relative;
	}

	.checkmark {
		height: 14px;
		width: 14px;
		position: absolute;
		background-color: #0081FF;
		border-radius: 100px;
		left: -15px;
		top: 40%;
	}

	.cartype {
		display: inline-block;
		width: 60px;
		color: #0173FE;
		font-size: 12px;
		font-weight: 400;
	}

	.editIconBtn {
		margin-right: 22px;
	}

	/deep/ .el-form-item__label {
		text-align: left;
	}

	/* 超出宽度省略号 */
	.rowline-show {
		max-width: 100px;
		word-break: break-all;
		white-space: nowrap;
		overflow: hidden; //隐藏溢出内容
		text-overflow: ellipsis; //超出的内容显示省略号
	}

	.tab {
		width: 100%;
		height: 40px;
		background-color: #f8f9fe;
		border: 1px solid #f2f2f2;
		border-bottom: none;
	}

	.tabItem {
		width: 108px;
		height: 40px;
		line-height: 40px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.borderTab {
		border: 1px solid #f2f2f2;
		border-bottom: none;
	}

	.tab-content1 {
		width: 100%;
	}

	.tab-content1-item {
		width: 49%;
		height: 135px;
		border-radius: 2px;
		box-sizing: border-box;
		background-color: #f7fbff;
		margin-bottom: 10px;

		&:nth-child(2n) {
			margin-left: 10px;
		}
	}
</style>
