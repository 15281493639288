<template >
	<el-dialog v-dialogDrag title="车辆分配" :visible.sync="showModel" width="45%" style="min-width: 400px;" :before-close="handleClose"
		:close-on-click-modal="false" :close-on-press-escape="false">
		<div>
			<div class="top-box">
				<div class="">车辆分组：</div>
				<div>
					<el-select :filter-method="getAddType" v-model="carToolType" collapse-tags filterable class="selectBox"
						clearable placeholder="请选择">
						<el-option v-for="item in carToolList" :key="item.id" :label="item.fGroupName" :value="item.id"
							:disabled="item.disabled">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="top-box1 font-size12 font-color-FF9900">
				请选择需要分配的车辆分组或输入新的分组。
			</div>
		</div>
		
		<span slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="handleConfirm">保 存</el-button>
		</span>
	</el-dialog>
</template>

<script>
	// import detailsAdd from './detailsAdd.vue'
	export default {
		components: {
			// detailsAdd
		},
		props: {
			showModel: {
				default: false
			},
			id: {
				default: ''
			},
			checkData : {
				default: []
			}
		},
		data() {
			return {
				carToolType : '',
				carToolList : [{id:'1',fGroupName : '测试1'},{id:'2',fGroupName : '测试2'}],
				tabelHeight : 'calc(80vh - 360px)',
				fBillNumber: '',
				// 调整单主表数据
				primaryData: {},
				// 调整单明细列表数据
				combinedList: [],
				// checkData: [],
				// 调整单明细列表字段
				combinedLabel: [{
						label: '商品',
						width: '280',
						prop: 'fGoodsName',
						align: 'left',
						fixed: false
					},
					{
						label: '当前价格',
						// width: '120',
						prop: 'fNowPrice',
						align: 'right',
						fixed: false
					},
					// {
					// 	label: '销售区域',
					// 	width: '220',
					// 	prop: 'fCustomerAreaNames',
					// 	align: 'left',
					// 	fixed: false
					// },
					// {
					// 	label: '客商等级',
					// 	width: '220',
					// 	prop: 'fGradeNames',
					// 	align: 'left',
					// 	fixed: false
					// },
					// {
					// 	label: '提货方式',
					// 	width: '220',
					// 	prop: 'fDeliveryNames',
					// 	align: 'left',
					// 	fixed: false
					// },
					{
						label: '挂牌价',
						// width: '120',
						prop: 'fGoodsPrice',
						align: 'center',
						fixed: false
					},
					{
						label: '积分数(个)',
						// width: '120',
						prop: 'fPointPrice',
						align: 'center',
						fixed: false
					},
					{
						label: '积分金额(元)',
						// width: '220',
						prop: 'fMaxPointMoney',
						align: 'right',
						fixed: false
					},
					{
						label: '可使用优惠券',
						// width: '160',
						prop: 'fIsEnableUseCoupon',
						align: 'center',
						fixed: false
					}
				],
				flags : false,
				addDetailsShow: false,
				useagepoint: false, //是否使用积分
				useagequan: false, //是否使用优惠券
				isTrue: false, //是否进行销售区域得显示
				listData : '',
				total : '',
				pageLoading : false
			}
		},
		mounted() {
			// this.goodsPriceInit();
			// this.acquireddd()
			// this.acquireResult()
			this.getTransportToolType()
		},
		watch: {
			carToolType(curVal, oldVal) {
				console.log(curVal,oldVal)
				if (curVal == '') {
					this.carToolType = 0;
				}else{
					this.carToolType = curVal
				}
				// this.getList();
			}
		},
		methods: {
			//下拉框输入的值
			getAddType(e){
				this.carToolType = e
				//输入框的值发生改变时触发
				console.log(this.carToolType)
			},
			//查询所有车辆的分组类别（原为运输类型）
			getTransportToolType() {
				console.log(this.checkData)
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/get-list-groupName', {}).then(
					result => { 	
						let carType = []; 
						result.obj.reslist.forEach(function(val) {
							carType.push({"id":val.value,"fGroupName": val.key})
						})
						this.carToolList = carType;
						console.error(this.carToolList)
					},
					rej => {}
				);
			},
			acquireResult() {
				this.ApiRequestPut('/api/mall/ebsale/goods-price-bill/get-shop-area-price', {}).then(
					result => {
						console.log(result, '查看结果')
						let a = ''
						a =  result.obj
						if (a == 1) {
							this.isTrue = true
							this.combinedLabel.splice(1, 0, {
								label: '销售区域',
								width: 'auto',
								prop: 'fCustomerAreaNames',
								align: 'left',
								fixed: false
							})
							this.combinedLabel.splice(2, 0, {
								label: '配送方式',
								width: 'auto',
								prop: 'fDeliveryNames',
								align: 'left',
								fixed: false
							})
						} else {
							this.isTrue = false
						}
					},
					rej => {}
				)
			},
			// 获取开店是时的状态
			acquireddd() {
				this.ApiRequestPost('/api/mall/ebshop/baseinfo/get-my-shop', {
					skipCount: 0,
					maxResultCount: 100,
				}).then(
					result => {
						var aaaa = result.obj.items[0].fIsEnablePoint
						var bbbb = result.obj.items[0].fIsEnablePointToGold
						console.log(aaaa, bbbb)
						if (aaaa == 0) {
							// this.combinedLabel.splice(2,2)
							this.useagepoint = true
						}
						if (bbbb == 0) {
							// this.combinedLabel.pop()
							this.useagequan = true
						}
					},
					error => {}
				)
			},
			blurChange(index, value, prop) {
				if (prop == "fPointPrice") {
					if (this.checkInputData(value[prop]) == '') {
						this.combinedList[index][prop] = '';
					} else {
						this.combinedList[index][prop] = parseInt(this.checkInputData(value[prop]))
					}
				} else {
					this.combinedList[index][prop] = this.checkInputData(value[prop])
				}

				if (value != '') {
					value.fMaxPointMoney = value.fPointPrice * this.primaryData.pointRMBPrice
				}
				console.log("value", "值改变")
			},
			// 输入验证
			checkInputData(val) {
				if (val == null) {
					return '';
				}
				// 清除"数字"和"."以外的字符
				let value_one = val.toString().replace(/[^\d.]/g, "");
				// 只保留第一个. 清除多余的
				value_one = value_one.replace(/\.{2,}/g, ".");
				if (value_one == '') {
					return '';
				} else {
					return parseFloat(value_one)
				}
			},
			deleteDetails() {
				for (let item of this.checkData) {
					this.combinedList.splice(
						this.combinedList.indexOf(item),
						1);
				}
				this.$refs.multipleTable.clearSelection();
			},
			// 全选
			handleSelectionAll(val) {
				this.checkData = val
			},
			// 选择
			handleSelectionChange(val) {
				this.checkData = val
			},
			// 新增初始化
			goodsPriceInit() {
				this.ApiRequestPost('/api/mall/ebsale/goods-price-bill/init', {}).then(
					result => {
						result.obj.fBeginTime = '';
						result.obj.fEndTime = '';
						result.obj.fRemark = '';
						this.primaryData = result.obj
					},
					error => {}
				)
			},
			// 保存
			handleConfirm() {
				// if (this.primaryData.fBeginTime == '' || this.primaryData.fBeginTime == null) {
				// 	return this.tipsInfo("请选择开始时间");
				// }
				// // 当前时间
				// let date = new Date();
				// // 选择的开始时间
				// let BeginTime = new Date(this.primaryData.fBeginTime);
				// if (BeginTime < date) {
				// 	return this.tipsInfo("开始时间不得小于当前时间");
				// }
				// if (this.combinedList < 1) {
				// 	return this.tipsInfo("请添加明细");
				// }
				// for (let item of this.combinedList) {
				// 	if (item.fGoodsPrice == '') {
				// 		this.tipsInfo("明细表中现金价不可为空")
				// 		return;
				// 	}
				// 	if (item.fPointPrice === '') {
				// 		this.tipsInfo("明细表中积分价不可为空")
				// 		return;
				// 	}
				// }
				this.saveData();
			},
			saveData() {
				if(!this.carToolType){
					this.$message.info("请选择分组")
					return;
				}
				console.log(this.carToolType)
				let fTransportToolIDs = [] 
				//遍历传过来的checkData将ID放入
				this.checkData.map(item=>{
					fTransportToolIDs.push(item.fTransportToolID)
				})
				this.primaryData = {
					"fGroupName": this.carToolType,
					"fTransportToolIDs": fTransportToolIDs
				}
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/distribution-transport-tools', this.primaryData).then(
					result => {
						this.handleClose();
					},
					error => {}
				)
			},
			// 新增弹窗关闭事件
			handleClose() {
				console.log(this.carToolType)
				if(!this.carToolType){
					this.flags = true
				}
				console.log(this.flags)
				this.$emit('change', !this.showModel,this.flags)
			},
			addDetailsChange(obj) {
				console.log(obj.list, '0000000000000000')
				console.log(obj.regionData, '11111111111111')
				this.addDetailsShow = obj.show;
				// 生成的明细 obj.list
				if (obj.list.length > 0) {
					for (let item of obj.list) {
						// item.fGoodsPrice = this.NumFormat(item.fGoodsPrice)
						// item.fNowPrice = this.NumFormat(item.fNowPrice)
						item.fMaxPointMoney = this.NumFormat(item.fMaxPointMoney)
					}
					if (this.isTrue == true) {
						for (let item of obj.list) {
							if (obj.regionData.length == 1) {
								item.fCustomerAreaNames = obj.regionData[0].fSaleArea
								item.fCustomerAreaIDs = obj.regionData[0].fSaleAreaID
							} else if (obj.regionData.length > 1) {
								let smallString = ''
								let smallStringid = ''
								for (let item1 of obj.regionData) {
									smallString = smallString + item1.fSaleArea + ','
									smallStringid = smallStringid + item1.fSaleAreaID + ','
								}
								smallString=smallString.slice(0,smallString.length-1)
								smallStringid=smallStringid.slice(0,smallStringid.length-1)
								item.fCustomerAreaNames = smallString
								item.fCustomerAreaIDs = smallStringid
							}
							//==========详情页面传过来的配送数据
							if (obj.sendList.length == 1) {
								item.fDeliveryNames = obj.sendList[0].fDelivery
								item.fDeliveryIDs = obj.sendList[0].fDeliveryID
							} else if (obj.sendList.length > 1) {
								let smallString = ''
								let smallStringid = ''
								for (let item1 of obj.sendList) {
									smallString = smallString + item1.fDelivery + ','
									smallStringid = smallStringid + item1.fDeliveryID + ','
								}
								smallString=smallString.slice(0,smallString.length-1)
								smallStringid=smallStringid.slice(0,smallStringid.length-1)
								item.fDeliveryNames = smallString
								item.fDeliveryIDs = smallStringid
							}
						}
					}else{
						for (let item of obj.list) {
							item.fCustomerAreaNames = ''
							item.fCustomerAreaIDs = ''
						}
						//======
						for (let item of obj.list) {
							item.fDeliveryNames = ''
							item.fDeliveryIDs = ''
						}
					}
					this.checkSameData(obj.list);
				}
			},
			checkSameData(list) {
				let _this = this;
				if (this.combinedList.length < 1) {
					this.combinedList = list;
				} else {
					let sameData = [];
					// 拿新的数据在旧的数据中去对比
					list.forEach((newData, newIndex) => {
						_this.combinedList.forEach(oldData => {
							let contrastResult = newData.fGoodsName == oldData.fGoodsName && newData
								.fGradeNames.split(',').sort().toString() ==
								oldData.fGradeNames.split(',').sort().toString() &&
								newData.fCustomerAreaNames.split(',').sort().toString() == oldData
								.fCustomerAreaNames.split(',').sort().toString() &&
								newData.fDeliveryNames.split(',').sort().toString() == oldData
								.fDeliveryNames.split(',').sort().toString();
							if (contrastResult) {
								sameData.push(newData);
							}
						})
					})
					if (sameData.length < 1) {
						list.forEach(newData => {
							this.combinedList.push(newData);
						})
					} else if (sameData.length < list.length) {
						let changeList = JSON.parse(JSON.stringify(list))
						sameData.forEach(data => {
							changeList.splice(
								changeList.indexOf(changeList.find(
									function(element) {
										return element.toString() === data.toString();
									})),
								1);
						})
						changeList.forEach(newData => {
							this.combinedList.push(newData);
						})
					}
				}
			},
			showAddDetailed() {
				console.log(this.primaryData.pointRMBPrice, '777777777777777')
				this.addDetailsShow = true;
			},
			tableRowClassName(rowIndex) {
				if (rowIndex.rowIndex % 2 === 0) {
					return 'warning-row'
				} else if (rowIndex.rowIndex % 2 === 1) {
					return 'success-row'
				}
			}
		}
	}
</script>

<style scoped="scoped">
	/deep/ .el-dialog {
		/* background-image: url(../../../../assets/bgimg/dialog%20.png); */
		filter: alpha(opacity=70);
		background-repeat: no-repeat;
		background-size: 70% 18%;
		-moz-background-size: 70% 18%;
	}

	.el-dialog {
		margin-top: 15vh !important;
	}

	/deep/ .el-button--primary {
		border: none;
	}

	/deep/ .el-textarea__inner {
		border-radius: 0;
	}

	.top-box {
		display: flex;
		justify-content: center;
		align-items : center;
	}
	.top-box1 {
		display: flex;
		justify-content: center;
		align-items : center;
		margin-left: 60px;
		margin-top: 10px;
	}

  .middle{
    height: calc(100vh - 556px);
  }

	.table-box {
		width: 100%;
		display: flex;
		justify-content: space-between;
		overflow-x: auto;
		margin: 10px 0;
	}
</style>
