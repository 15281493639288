<template>
	<el-dialog v-dialogDrag title="车辆分享" :visible.sync="showModel" width="45%" style="min-width: 400px;"
		:before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false" v-loading="loading">
		<span class="flex-center">
			<span>分享方式：</span>
			<div>
				<el-radio v-model="carflag" label="1" @change="carShare">分组分享</el-radio>
				<el-radio v-model="carflag" label="2" @change="GroupShare">车辆分享</el-radio>
			</div>
			<!-- <el-button type="primary" @click="GroupShare">分组分享</el-button>
			<el-button type="primary" @click="carShare">车辆分享</el-button> -->
		</span>
		<div>
			<div class="top-box" v-if="carGroupflag">
				<div class="">车辆分组：</div>
				<div>
					<el-select :filter-method="getAddTypes" multiple v-model="carToolType1" @change="selectCarAdd"
						collapse-tags filterable class="selectBox" clearable placeholder="请选择">
						<el-option v-for="item in carToolList" :key="item.value" :label="item.key" :value="item.value"
							:disabled="item.disabled">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="top-box" v-if="carSelect">
				<div class="">车辆选择：</div>
				<div>
					<el-select :filter-method="getAddTypes3" multiple v-model="carToolType3" @change="selectCarOneAdd"
						collapse-tags filterable class="selectBox" clearable placeholder="请选择">
						<el-option v-for="item in carToolList3" :key="item.fTransportToolID" :label="item.fPlateNumber"
							:value="item.fTransportToolID" :disabled="item.disabled">
						</el-option>
					</el-select>
				</div>
				<el-button @click="GroupShare" type="primary" style="width:60px;height:30px; position: absolute; right: 14rem;">选择</el-button>
			</div>
			<div class="top-box">
				<div class="">人员选择：</div>
				<div>
					<el-select :filter-method="getAddTypes2" multiple v-model="carToolType2" @change="selectPersonAdd"
						collapse-tags filterable class="selectBox" clearable placeholder="请选择">
						<el-option v-for="item in carToolList2" :key="item.fUserId" :label="item.fUserName"
							:value="item.fUserId" :disabled="item.disabled">
						</el-option>
					</el-select>
				</div>
			</div>
			
			<div class="top-box" v-if="carflag == '2'">
				<!-- 选择车辆弹框 -->
				<el-dialog v-dialogDrag v-if="dialogChsCar" title="请选择车辆" :visible.sync="dialogChsCar" width="700px"
					:close-on-press-escape="false" :close-on-click-modal="false" :append-to-body="true"
					v-loading="loading">
					<div class="top-box flex-row-align-center">
						<div style="background-color: #0173FE;color: #FFFFFF; cursor: pointer;"
							@click="dialogAddCar = true;"
							class="add-car-btn margin-r-10 background-color-theme flex-row-center-center font-size13">
							新增车辆
						</div>
						<div style="padding-right: 5px; width: 50%">
							<el-select multiple collapse-tags @change="selectAdd" v-model="carToolType"
								class="selectBox" clearable placeholder="请选择">
								<el-option v-for="item in carToolList1" :key="item.value" :label="item.key"
									:value="item.value" :disabled="item.disabled">
								</el-option>
							</el-select>
						</div>
						<el-input placeholder="请输入车牌号进行模糊查询" prefix-icon="el-icon-search" v-model="searchText"
							@input="filter(searchText)" />
					</div>
					<el-table :data="transporttool.slice((currentPage - 1) * pagesize, currentPage * pagesize)"
						@selection-change="handleSelectionChange" @select-all="handleSelectionAll" border
						style="width: 100%; margin-top:5px;" height="500px">
						<el-table-column type="selection" fixed width="50" align="center" />
						<el-table-column prop="fPlateNumber" label="车牌号"></el-table-column>
						<el-table-column prop="fLinkMan" label="司机"></el-table-column>
						<el-table-column prop="fLinkManTelCode" label="电话"></el-table-column>
						<el-table-column prop="fGroupName" label="分组"></el-table-column>
						<el-table-column prop="fTransportToolType" label="类型"></el-table-column>
						<el-table-column prop="fMaximumLoad" label="载货量（净重）" width="100"></el-table-column>
					</el-table>
					<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
						:currentPage="currentPage" :pagesize="pagesize" :total="total" class="margin-t-10">
					</pagination>
					<span slot="footer" class="dialog-footer">
						<el-button @click="dialogChsCar = false" class="table-btn" style="">取 消</el-button>
						<el-button @click="chooseConfirm" class="table-btn background-color-theme">确 定</el-button>
					</span>
				</el-dialog>
				<!-- 新增车辆弹框 -->
				<el-dialog v-dialogDrag v-if="dialogAddCar" title="新增车辆" :visible.sync="dialogAddCar" width="600px"
					:close-on-press-escape="false" :close-on-click-modal="false" :append-to-body="true"
					@close="hiddClick">
					<el-form :model="addCarForm" :rules="rules" ref="addCarForm" label-width="130px">
						<el-form-item label="类型" prop="carToolType">
							<el-select filterable v-model="addCarForm.carToolType" clearable placeholder="请选择">
								<el-option v-for="item in carToolgroupList" :key="item.fTransportToolTypeID" @click.native="CarToolTypechange(item)"
									:label="item.fTransportToolType" :value="item.fTransportToolTypeID">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="车号/船号" prop="fPlateNumber">
							<el-input v-model="addCarForm.fPlateNumber" placeholder="请输入车号/船号" @blur="blurInput">
							</el-input>
						</el-form-item>
						<el-form-item label="司机" prop="fLinkMan">
							<el-input v-model="addCarForm.fLinkMan" placeholder="请输入司机姓名"></el-input>
						</el-form-item>
						<el-form-item label="电话" prop="fLinkManTelCode">
							<el-input v-model="addCarForm.fLinkManTelCode" placeholder="请输入电话号码"></el-input>
						</el-form-item>
						<el-form-item label="载货量/净重(吨)" prop="fMaximumLoad">
							<el-input v-model.number="addCarForm.fMaximumLoad" placeholder="请输入载货量/净重"></el-input>
						</el-form-item>
						<el-form-item label="分组" prop="carGroupType">
							<el-select filterable allow-create default-first-option :filter-method="getAddType"
								v-model="addCarForm.carGroupType" clearable placeholder="请选择">
								<el-option v-for="item in carGroupList" :key="item.value" :label="item.key"
									:value="item.value">
								</el-option>
							</el-select>
							<div class="font-size12 font-color-FF9900">
								请选择需要分配的车辆分组或输入新的分组。
							</div>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button @click="hiddClick" style="width:60px;height:30px;">取 消</el-button>
						<el-button @click="submitForm" class="background-color-theme" style="width:60px;height:30px;">确
							定</el-button>
					</div>
				</el-dialog>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="handleConfirm">保 存</el-button>
		</span>
	</el-dialog>
</template>

<script>
	// import detailsAdd from './detailsAdd.vue'
	import pagination from '@/components/pagination/pagination.vue'
	export default {
		components: {
			pagination
			// detailsAdd
		},
		props: {
			showModel: {
				default: false
			},
			id: {
				default: ''
			},
			checkData: {
				default: []
			}
		},
		data() {
      let validateInput = (rule, value, callback) => {
        if(this.selectval.fTransportToolClassID == 1 ){
          //这里是双判断 原因是 我要截取第一个字是交警12123的汉字
          console.log( /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领]{1}$/.test(value.charAt(0)),'測試')
         if(value.length >= this.carrule+1 && this.isVehicleNumber(value) && /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领]{1}$/.test(value.charAt(0))){
          callback();
         }else{
          callback(new Error("请输入正确的车牌号，除首位汉字外，车牌号只能包含数字或字母(不能包含字母I和O)"));
         }
        } else {
				if (!this.checkSpecialKey(value)) {
					callback(new Error("车船号只能输入汉字、数字和字母！"));
				} else {
					callback();
				}
        }
			};
			return {
        carrule:null,
        selectval:{},
				carSelect : false,
				carToolType: '',
				carToolType1: '',
				carToolType2: '',
				carToolType3: [],
				dialogAddCar: false,
				loading: false,
				carToolList: [{
					id: '1',
					fGroupName: '测试1'
				}, {
					id: '2',
					fGroupName: '测试2'
				}],
				oldToolData: [],
				carToolList2: [],
				oldChooseData2: [],
				carToolList3: [],
				oldChooseData3: [],
				tabelHeight: 'calc(80vh - 360px)',
				fBillNumber: '',
				// 调整单主表数据
				primaryData: {},
				// 调整单明细列表数据
				combinedList: [],
				// checkData: [],
				// 调整单明细列表字段
				combinedLabel: [{
						label: '商品',
						width: '280',
						prop: 'fGoodsName',
						align: 'left',
						fixed: false
					},
					{
						label: '当前价格',
						// width: '120',
						prop: 'fNowPrice',
						align: 'right',
						fixed: false
					},
					// {
					// 	label: '销售区域',
					// 	width: '220',
					// 	prop: 'fCustomerAreaNames',
					// 	align: 'left',
					// 	fixed: false
					// },
					// {
					// 	label: '客商等级',
					// 	width: '220',
					// 	prop: 'fGradeNames',
					// 	align: 'left',
					// 	fixed: false
					// },
					// {
					// 	label: '提货方式',
					// 	width: '220',
					// 	prop: 'fDeliveryNames',
					// 	align: 'left',
					// 	fixed: false
					// },
					{
						label: '挂牌价',
						// width: '120',
						prop: 'fGoodsPrice',
						align: 'center',
						fixed: false
					},
					{
						label: '积分数(个)',
						// width: '120',
						prop: 'fPointPrice',
						align: 'center',
						fixed: false
					},
					{
						label: '积分金额(元)',
						// width: '220',
						prop: 'fMaxPointMoney',
						align: 'right',
						fixed: false
					},
					{
						label: '可使用优惠券',
						// width: '160',
						prop: 'fIsEnableUseCoupon',
						align: 'center',
						fixed: false
					}
				],
				carGroupflag: true,
				rules: {
					carGroupType: [{
						message: '请选择分组',
						trigger: 'blur'
					}],
					carToolType: [{
						required: true,
						message: '请选择类型',
						trigger: 'blur'
					}],
					fPlateNumber: [{
							required: true,
							message: '请输入车号/船号',
							trigger: 'change'
						},
						{
							validator: validateInput,
							trigger: 'blur'
						}
					],
					fLinkMan: [{
						required: true,
						message: '请输入司机姓名',
						trigger: 'change'
					}],
					fLinkManTelCode: [{
						required: true,
						validator: (rule, value, callback) => {
							if (value === '') {
								callback(new Error('请输入手机号'));
							} else {
								let phoneNumberRul =
									/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
								if (phoneNumberRul.test(value)) {
									callback();
								} else {
									callback(new Error('请输入正确的手机号码'));
								}
							}
						},
						trigger: 'blur'
					}],
					fMaximumLoad: [
						// {
						// 	validator: (rule, value, callback) => {
						// 		if (!value) {
						// 			return callback(new Error('重量不能为空'));
						// 		}
						// 		setTimeout(() => {
						// 			if (value < 10) {
						// 				callback(new Error('重量必须大于10'));
						// 			} else {
						// 				callback();
						// 			}

						// 		}, 1000);
						// 	},
						// 	required: true,
						// 	trigger: 'change'
						// },
						{
							required: true,
							message: '请输入载货量/净重',
							trigger: 'change'
						},
						{
							type: 'number',
							message: '载货量/净重必须为数字'
						}
					],
				},
				flags: false,
				addDetailsShow: false,
				useagepoint: false, //是否使用积分
				useagequan: false, //是否使用优惠券
				isTrue: false, //是否进行销售区域得显示
				listData: '',
				total: '',
				pageLoading: false,
				carflag: '1',
				carData: [],
				carToolList1: [],
				transporttool: [],
				oldChooseData: [],
				total: 0, //总条数
				pagesize: 20, //每页显示条目个数
				currentPage: 1, //当前页
				// 选择车辆模态窗显示变量
				dialogChsCar: false,
				// 已选择的车辆
				chooseCar: [],
				// 已选择确定的车辆
				chooseConfirmCar: [],
				carGroupList: [],
				carGroupType: '',
				addCarForm: {
					carToolType: '', //类型
					carGroupType: '', //分组
					fPlateNumber: '', //车号
					fLinkMan: '', //司机
					fLinkManTelCode: '',
					fMaximumLoad: '',
					fFrequency: '',
					carGroupList: []
				},
				searchText: '',
				carToolgroupList: [],
				carToolgroupListType: '',
			}
		},
		mounted() {
      this.getcarrule()
			// this.goodsPriceInit();
			// this.acquireddd()
			// this.acquireResult()
			this.getTransportToolType()
			//查询所有的车辆分组类别
			this.getgroupType();
			// 派车车辆查询
			this.getTransporttoolList();
			//查询所有人员
			this.getPersonToolType();
		},
		watch: {
			carToolType(curVal, oldVal) {
				console.log(curVal, oldVal)
				if (curVal == '') {
					this.carToolType = 0;
				} else {
					this.carToolType = curVal
				}
				// this.getList();
			},
			dialogAddCar() {
				this.getTransportgroupList()
				this.getgroupTypes()
			},
		},
		methods: {
      //车辆号码验证长度
        getcarrule(){
				this.ApiRequestPost('/api/mall/ebbase/para-value/get-plate-number-min-length', {}).then(
					result => {
						this.carrule= result
            console.log(this.carrule,'车辆验证长度')
					},
					rej => {}
				);
      },
      CarToolTypechange(val){
       this.selectval=val
      },
      isVehicleNumber (vehicleNumber) {
       let answer = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领学警港澳][0-9a-hj-np-zA-HJ-NP-Z]{5,}$/.test(vehicleNumber)
       console.log(answer)
       return answer
      },
			filter(val) {
				if (val) {
					this.transporttool = this.initTransporttool.filter(item => item.fPlateNumber.indexOf(val) != -1);
				} else {
					this.transporttool = this.initTransporttool;
				}

				this.total = this.transporttool.length
				this.currentPage = 1
			},
			//下拉框输入的值
			getAddTypes(e) {
				this.carToolType1 = e
				//输入框的值发生改变时触发
				console.log(this.carToolType)
			},
			//下拉框输入的值
			getAddTypes2(e) {
				this.carToolType2 = e
				//输入框的值发生改变时触发
				console.log(this.carToolType2)
			},
			//下拉框输入的值
			getAddTypes3(e) {
				this.carToolType3 = e
				//输入框的值发生改变时触发
				console.log(this.carToolType3)
			},
			//查询所有车辆的分组类别（原为运输类型）
			getTransportToolType() {
				console.log(this.checkData)
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/get-list-groupName', {}).then(
					result => {
						this.carToolType1 = []
						this.carToolList = []
						// result.obj.forEach(function(val) {
						// 	carType.push({
						// 		"id": val.fUserId,
						// 		"fGroupName": val.fUserName
						// 	})
						// })
						console.log(result)
						// result.obj.unshift('全部')
						let list = result.obj.reslist
						list.unshift({
							value: `全部`,
							key: '全部',
						})
						this.carToolList = list
						list.map(item => {
							this.carToolType1.push(item.value)
							this.oldToolData.push(item.value)
						})

						// let carType = [];
						// result.obj.reslist.forEach(function(val) {
						// 	carType.push({
						// 		"id": val.value,
						// 		"fGroupName": val.key
						// 	})
						// })
						// this.carToolList = carType;
						console.error(this.carToolList)
					},
					rej => {}
				);
			},
			//查询所有人员
			getPersonToolType() {
				console.log(this.checkData)
				this.ApiRequestPost('api/mall/sys/identity-users/get-enbale-isnotmanager-list', {}).then(
					result => {
						this.carToolType2 = []
						this.carToolList2 = []
						// result.obj.forEach(function(val) {
						// 	carType.push({
						// 		"id": val.fUserId,
						// 		"fGroupName": val.fUserName
						// 	})
						// })
						console.log(result)
						// result.obj.unshift('全部')
						let list = result.obj
						list.unshift({
							fUserId: `全部`,
							fUserName: '全部',
						})
						this.carToolList2 = list
						list.map(item => {
							this.carToolType2.push(item.fUserId)
							this.oldChooseData2.push(item.fUserId)
						})

						// this.carToolList2 = carType;
						// console.error(this.carToolList2)
					},
					rej => {}
				);
			},
			acquireResult() {
				this.ApiRequestPut('/api/mall/ebsale/goods-price-bill/get-shop-area-price', {}).then(
					result => {
						console.log(result, '查看结果')
						let a = ''
						a = result.obj
						if (a == 1) {
							this.isTrue = true
							this.combinedLabel.splice(1, 0, {
								label: '销售区域',
								width: 'auto',
								prop: 'fCustomerAreaNames',
								align: 'left',
								fixed: false
							})
							this.combinedLabel.splice(2, 0, {
								label: '配送方式',
								width: 'auto',
								prop: 'fDeliveryNames',
								align: 'left',
								fixed: false
							})
						} else {
							this.isTrue = false
						}
					},
					rej => {}
				)
			},
			// 获取开店是时的状态
			acquireddd() {
				this.ApiRequestPost('/api/mall/ebshop/baseinfo/get-my-shop', {
					skipCount: 0,
					maxResultCount: 100,
				}).then(
					result => {
						var aaaa = result.obj.items[0].fIsEnablePoint
						var bbbb = result.obj.items[0].fIsEnablePointToGold
						console.log(aaaa, bbbb)
						if (aaaa == 0) {
							// this.combinedLabel.splice(2,2)
							this.useagepoint = true
						}
						if (bbbb == 0) {
							// this.combinedLabel.pop()
							this.useagequan = true
						}
					},
					error => {}
				)
			},
			blurChange(index, value, prop) {
				if (prop == "fPointPrice") {
					if (this.checkInputData(value[prop]) == '') {
						this.combinedList[index][prop] = '';
					} else {
						this.combinedList[index][prop] = parseInt(this.checkInputData(value[prop]))
					}
				} else {
					this.combinedList[index][prop] = this.checkInputData(value[prop])
				}

				if (value != '') {
					value.fMaxPointMoney = value.fPointPrice * this.primaryData.pointRMBPrice
				}
				console.log("value", "值改变")
			},
			// 输入验证
			checkInputData(val) {
				if (val == null) {
					return '';
				}
				// 清除"数字"和"."以外的字符
				let value_one = val.toString().replace(/[^\d.]/g, "");
				// 只保留第一个. 清除多余的
				value_one = value_one.replace(/\.{2,}/g, ".");
				if (value_one == '') {
					return '';
				} else {
					return parseFloat(value_one)
				}
			},
			deleteDetails() {
				for (let item of this.checkData) {
					this.combinedList.splice(
						this.combinedList.indexOf(item),
						1);
				}
				this.$refs.multipleTable.clearSelection();
			},
			// 全选
			handleSelectionAll(val) {
				this.checkData = val
			},
			// 选择
			handleSelectionChange(val) {
				this.checkData = val
			},
			// 新增初始化
			goodsPriceInit() {
				this.ApiRequestPost('/api/mall/ebsale/goods-price-bill/init', {}).then(
					result => {
						result.obj.fBeginTime = '';
						result.obj.fEndTime = '';
						result.obj.fRemark = '';
						this.primaryData = result.obj
					},
					error => {}
				)
			},
			// 保存
			handleConfirm() {
				// if (this.primaryData.fBeginTime == '' || this.primaryData.fBeginTime == null) {
				// 	return this.tipsInfo("请选择开始时间");
				// }
				// // 当前时间
				// let date = new Date();
				// // 选择的开始时间
				// let BeginTime = new Date(this.primaryData.fBeginTime);
				// if (BeginTime < date) {
				// 	return this.tipsInfo("开始时间不得小于当前时间");
				// }
				// if (this.combinedList < 1) {
				// 	return this.tipsInfo("请添加明细");
				// }
				// for (let item of this.combinedList) {
				// 	if (item.fGoodsPrice == '') {
				// 		this.tipsInfo("明细表中现金价不可为空")
				// 		return;
				// 	}
				// 	if (item.fPointPrice === '') {
				// 		this.tipsInfo("明细表中积分价不可为空")
				// 		return;
				// 	}
				// }
				this.saveData();
			},
			saveData() {
				if(this.carToolType1.length > 0){
					this.carToolType1.map((item,index)=>{
						if(item == '全部'){
							this.carToolType1.splice(index,1)
						}
					})
				}
				if(this.carToolType2.length > 0){
					this.carToolType2.map((item,index)=>{
						if(item == '全部'){
							this.carToolType2.splice(index,1)
						}
					})
				}
				if(this.carToolType3.length > 0){
					this.carToolType3.map((item,index)=>{
						if(item == '全部'){
							this.carToolType3.splice(index,1)
						}
					})
				}
				if(this.carflag == '2' && this.carToolType3.length == 0){
					this.$message.info("请选择要分享的车辆")
					return
				}
				if(this.carToolType2.length == 0){
					this.$message.info("请选择要分享的人员")
					return
				}
				this.primaryData = {
					"fType": this.carflag,
					"fGroupName": this.carToolType1.length > 0 ? this.carToolType1 : [],
					"fTransportToolIDs": this.carToolType3.length > 0 ? this.carToolType3 : [],
					"fUserIDs": this.carToolType2.length > 0 ? this.carToolType2 : []
				}
				this.loading = true
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/share-transport-tool', this.primaryData)
					.then(
						result => {
							this.loading = false
							this.$message.info(result.message)
							this.handleClose();
						},
						error => {}
					)
			},
			// 新增弹窗关闭事件
			handleClose() {
				console.log(this.carToolType)
				if (!this.carToolType) {
					this.flags = true
				}
				console.log(this.flags)
				this.$emit('change', !this.showModel, this.flags)
			},
			addDetailsChange(obj) {
				console.log(obj.list, '0000000000000000')
				console.log(obj.regionData, '11111111111111')
				this.addDetailsShow = obj.show;
				// 生成的明细 obj.list
				if (obj.list.length > 0) {
					for (let item of obj.list) {
						// item.fGoodsPrice = this.NumFormat(item.fGoodsPrice)
						// item.fNowPrice = this.NumFormat(item.fNowPrice)
						item.fMaxPointMoney = this.NumFormat(item.fMaxPointMoney)
					}
					if (this.isTrue == true) {
						for (let item of obj.list) {
							if (obj.regionData.length == 1) {
								item.fCustomerAreaNames = obj.regionData[0].fSaleArea
								item.fCustomerAreaIDs = obj.regionData[0].fSaleAreaID
							} else if (obj.regionData.length > 1) {
								let smallString = ''
								let smallStringid = ''
								for (let item1 of obj.regionData) {
									smallString = smallString + item1.fSaleArea + ','
									smallStringid = smallStringid + item1.fSaleAreaID + ','
								}
								smallString = smallString.slice(0, smallString.length - 1)
								smallStringid = smallStringid.slice(0, smallStringid.length - 1)
								item.fCustomerAreaNames = smallString
								item.fCustomerAreaIDs = smallStringid
							}
							//==========详情页面传过来的配送数据
							if (obj.sendList.length == 1) {
								item.fDeliveryNames = obj.sendList[0].fDelivery
								item.fDeliveryIDs = obj.sendList[0].fDeliveryID
							} else if (obj.sendList.length > 1) {
								let smallString = ''
								let smallStringid = ''
								for (let item1 of obj.sendList) {
									smallString = smallString + item1.fDelivery + ','
									smallStringid = smallStringid + item1.fDeliveryID + ','
								}
								smallString = smallString.slice(0, smallString.length - 1)
								smallStringid = smallStringid.slice(0, smallStringid.length - 1)
								item.fDeliveryNames = smallString
								item.fDeliveryIDs = smallStringid
							}
						}
					} else {
						for (let item of obj.list) {
							item.fCustomerAreaNames = ''
							item.fCustomerAreaIDs = ''
						}
						//======
						for (let item of obj.list) {
							item.fDeliveryNames = ''
							item.fDeliveryIDs = ''
						}
					}
					this.checkSameData(obj.list);
				}
			},
			checkSameData(list) {
				let _this = this;
				if (this.combinedList.length < 1) {
					this.combinedList = list;
				} else {
					let sameData = [];
					// 拿新的数据在旧的数据中去对比
					list.forEach((newData, newIndex) => {
						_this.combinedList.forEach(oldData => {
							let contrastResult = newData.fGoodsName == oldData.fGoodsName && newData
								.fGradeNames.split(',').sort().toString() ==
								oldData.fGradeNames.split(',').sort().toString() &&
								newData.fCustomerAreaNames.split(',').sort().toString() == oldData
								.fCustomerAreaNames.split(',').sort().toString() &&
								newData.fDeliveryNames.split(',').sort().toString() == oldData
								.fDeliveryNames.split(',').sort().toString();
							if (contrastResult) {
								sameData.push(newData);
							}
						})
					})
					if (sameData.length < 1) {
						list.forEach(newData => {
							this.combinedList.push(newData);
						})
					} else if (sameData.length < list.length) {
						let changeList = JSON.parse(JSON.stringify(list))
						sameData.forEach(data => {
							changeList.splice(
								changeList.indexOf(changeList.find(
									function(element) {
										return element.toString() === data.toString();
									})),
								1);
						})
						changeList.forEach(newData => {
							this.combinedList.push(newData);
						})
					}
				}
			},
			showAddDetailed() {
				console.log(this.primaryData.pointRMBPrice, '777777777777777')
				this.addDetailsShow = true;
			},
			tableRowClassName(rowIndex) {
				if (rowIndex.rowIndex % 2 === 0) {
					return 'warning-row'
				} else if (rowIndex.rowIndex % 2 === 1) {
					return 'success-row'
				}
			},
			GroupShare() {
				console.log(111)
				console.log(this.carflag)
				if (this.carflag == '2') {
					this.dialogChsCar = true
					this.carGroupflag = false
				}

			},
			carShare() {
				console.log(222)
				if (this.carflag == '1') {
					this.carGroupflag = true
					this.carToolList3 = []
					this.carToolType3 = []
					this.oldChooseData3 = []
					this.chooseConfirmCar = []
					this.carSelect = false
				}
			},
			hiddClick() {
				console.log('1212121');
				this.dialogAddCar = false;
				this.resetAddCarFrom();
			},
			resetAddCarFrom() {
				this.addCarForm = {
					carGroupType: '', //分组名
					carToolType: '', //类型
					fPlateNumber: '', //车号
					fLinkMan: '', //司机
					fLinkManTelCode: '',
					fMaximumLoad: ''
				}
			},
			// 派车车辆查询
			getTransporttoolList() {
				this.loading = true;
				console.log(this.carToolType)
				if (this.carToolType == -1) {
					this.carToolType = ''
				}
				this.ApiRequestPost("api/mall/ebcustomer/transporttool/get-list-by-groupName", {
					filter: this.searchText,
					// fGroupID: this.carToolType,
					"fGroupName": this.carToolType,
					// maxResultCount: this.pagesize,
					// skipCount: (this.currentPage - 1) * this.pagesize
				}).then(res => {
					// this.carToolType =
					this.initTransporttool = res.obj.items;
					this.transporttool = JSON.parse(JSON.stringify(res.obj.items));
					this.total = res.obj.totalCount;
					this.loading = false;
				}).catch(err => {

				})
			},
			getTransportgroupList() {
				this.ApiRequestPost('api/mall/ebbase/transportTool-Type/get-onstatus-list', {}).then(
					result => {
						console.log('车辆信息列表===================', result)
						this.carToolgroupList = result.obj.items;
						console.log(this.carToolgroupList)
						if (this.carToolType == "") {
							this.carToolType = this.carToolgroupList[0].fTransportToolTypeID
						}
					},
					rej => {}
				);
			},
			selectAdd(val) {
				console.log(val)
				this.selectAll(val)
			},
			selectAll(val) {
				const allValues = this.carToolList1.map(item => {
					console.log(item.value)
					return item.value;
				});
				// 用来储存上一次选择的值，可进行对比
				const oldVal = this.oldChooseData.length > 0 ? this.oldChooseData : [];

				// 若选择全部
				if (val.includes('全部')) {
					this.carToolType = allValues;
				}

				// 取消全部选中， 上次有， 当前没有， 表示取消全选
				if (oldVal.includes('全部') && !val.includes('全部')) {
					this.carToolType = [];
				}

				// 点击非全部选中，需要排除全部选中 以及 当前点击的选项
				// 新老数据都有全部选中
				if (oldVal.includes('全部') && val.includes('全部')) {
					const index = val.indexOf('全部');
					val.splice(index, 1); // 排除全选选项
					this.carToolType = val;
				}

				// 全选未选，但是其他选项都全部选上了，则全选选上
				if (!oldVal.includes('全部') && !val.includes('全部')) {
					if (val.length === allValues.length - 1) {
						this.carToolType = ['全部'].concat(val);
					}
				}
				console.log(this.carToolType)
				this.getTransporttoolList(this.carGroupType)
				// 储存当前选择的最后结果 作为下次的老数据
				this.oldChooseData = this.carToolType
			},

			selectPersonAdd(val) {
				console.log(val)
				this.selectPersonAddAll(val)
			},
			selectPersonAddAll(val) {
				const allValues = this.carToolList2.map(item => {
					console.log(item.fUserId)
					return item.fUserId;
				});
				// 用来储存上一次选择的值，可进行对比
				const oldVal = this.oldChooseData2.length > 0 ? this.oldChooseData2 : [];

				// 若选择全部
				if (val.includes('全部')) {
					this.carToolType2 = allValues;
				}

				// 取消全部选中， 上次有， 当前没有， 表示取消全选
				if (oldVal.includes('全部') && !val.includes('全部')) {
					this.carToolType2 = [];
				}

				// 点击非全部选中，需要排除全部选中 以及 当前点击的选项
				// 新老数据都有全部选中
				if (oldVal.includes('全部') && val.includes('全部')) {
					const index = val.indexOf('全部');
					val.splice(index, 1); // 排除全选选项
					this.carToolType2 = val;
				}

				// 全选未选，但是其他选项都全部选上了，则全选选上
				if (!oldVal.includes('全部') && !val.includes('全部')) {
					if (val.length === allValues.length - 1) {
						this.carToolType2 = ['全部'].concat(val);
					}
				}
				console.log(this.carToolType2)
				// this.getTransporttoolList(this.carGroupType)s
				// 储存当前选择的最后结果 作为下次的老数据
				this.oldChooseData2 = this.carToolType2
			},

			selectCarAdd(val) {
				console.log(val)
				this.selectCarAddAll(val)
			},
			selectCarAddAll(val) {
				const allValues = this.carToolList.map(item => {
					console.log(item.value)
					return item.value;
				});
				// 用来储存上一次选择的值，可进行对比
				const oldVal = this.oldToolData.length > 0 ? this.oldToolData : [];

				// 若选择全部
				if (val.includes('全部')) {
					this.carToolType1 = allValues;
				}

				// 取消全部选中， 上次有， 当前没有， 表示取消全选
				if (oldVal.includes('全部') && !val.includes('全部')) {
					this.carToolType1 = [];
				}

				// 点击非全部选中，需要排除全部选中 以及 当前点击的选项
				// 新老数据都有全部选中
				if (oldVal.includes('全部') && val.includes('全部')) {
					const index = val.indexOf('全部');
					val.splice(index, 1); // 排除全选选项
					this.carToolType1 = val;
				}

				// 全选未选，但是其他选项都全部选上了，则全选选上
				if (!oldVal.includes('全部') && !val.includes('全部')) {
					if (val.length === allValues.length - 1) {
						this.carToolType1 = ['全部'].concat(val);
					}
				}
				console.log(this.carToolType1)
				// this.getTransportToolType(this.carGroupType)
				// 储存当前选择的最后结果 作为下次的老数据
				this.oldToolData = this.carToolType1
			},
			selectCarOneAdd(val) {
				console.log(val)
				this.selectCarAddOneAll(val)
			},
			selectCarAddOneAll(val) {
				const allValues = this.carToolList3.map(item => {
					console.log(item.fCustomerID)
					return item.fTransportToolID;
				});
				// 用来储存上一次选择的值，可进行对比
				const oldVal = this.oldChooseData3.length > 0 ? this.oldChooseData3 : [];
			
				// 若选择全部
				if (val.includes('全部')) {
					this.carToolType3 = allValues;
				}
			
				// 取消全部选中， 上次有， 当前没有， 表示取消全选
				if (oldVal.includes('全部') && !val.includes('全部')) {
					this.carToolType3 = [];
				}
			
				// 点击非全部选中，需要排除全部选中 以及 当前点击的选项
				// 新老数据都有全部选中
				if (oldVal.includes('全部') && val.includes('全部')) {
					const index = val.indexOf('全部');
					val.splice(index, 1); // 排除全选选项
					this.carToolType3 = val;
				}
			
				// 全选未选，但是其他选项都全部选上了，则全选选上
				if (!oldVal.includes('全部') && !val.includes('全部')) {
					if (val.length === allValues.length - 1) {
						this.carToolType3 = ['全部'].concat(val);
					}
				}
				console.log(this.carToolType3)
				// this.getTransportToolType(this.carGroupType)
				// 储存当前选择的最后结果 作为下次的老数据
				this.oldChooseData3 = this.carToolType3
			},
			// 全选
			handleSelectionAll(val) {
				this.chooseCar = val
			},
			// 选择
			handleSelectionChange(val) {
				this.chooseCar = val
			},
			// 移除已选车辆
			deleteChoose(item) {
				this.chooseConfirmCar.splice(
					this.chooseConfirmCar.indexOf(this.chooseConfirmCar.find(
						function(element) {
							return element.fTransportToolID === item.fTransportToolID;
						})),
					1);
				this.chooseCar.splice(
					this.chooseCar.indexOf(this.chooseCar.find(
						function(element) {
							return element.fTransportToolID === item.fTransportToolID;
						})),
					1);
			},
			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			//currentPage（当前页数） 改变时会触发
			handleCurrentChange(val) {
				this.currentPage = val;
				console.log(`当前页: ${val}`);
				// this.getTransporttoolList();
			},
			chooseConfirm() {
				let array = JSON.parse(JSON.stringify(this.chooseCar))
				this.chooseConfirmCar = []
				array.map(item => {
					item.fFrequency = 1
				})
				if (this.chooseConfirmCar.length < 1) {
					console.log(array)
					this.chooseConfirmCar = array
				} else {
					this.chooseConfirmCar.forEach(item => {
						array.splice(
							array.indexOf(array.find(
								function(element) {
									return element.fTransportToolID === item.fTransportToolID;
								})),
							1);
					})
					console.log(array)
					if (array.length > 0) {
						array.forEach(item => {
							this.chooseConfirmCar.push(item)
							// this.carToolType3.push(item.fTransportToolID)
						})
					}
				}
				let list = []
				this.carToolList3 = []
				this.carToolType3 = []
				list = this.chooseConfirmCar
				list.unshift({
					fCustomerName: `全部`,
					fTransportToolID: '全部',
				})
				this.carToolList3 = list
				list.map(item => {
					this.carToolType3.push(item.fTransportToolID)
					this.oldChooseData3.push(item.fTransportToolID)
				})
				this.carToolType1 = this.chooseConfirmCar.length > 0 ? [] : this.carToolType1
				this.carSelect = true
				this.dialogChsCar = false;
			},
			getAddType(e) {
				this.addCarForm.carGroupType = e
				//输入框的值发生改变时触发
				console.log(this.addCarForm.carGroupType)
			},
			// 车船号 小写字符转大写
			blurInput() {
				this.addCarForm.fPlateNumber = this.checkStrWord(this.addCarForm.fPlateNumber)
			},
			//查询所有的车辆分组类别
			getgroupType() {
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/get-list-groupName', {}).then(
					result => {
						console.log('分组信息列表===================', result)
						this.carToolType = []
						this.carToolList1 = []
						console.log(result)
						// result.obj.unshift('全部')
						let list = result.obj.reslist
						list.unshift({
							key: `全部(${result.obj.fSumcount})`,
							value: '全部',
						})
						this.carToolList1 = list
						list.map(item => {
							this.carToolType.push(item.value)
						})

						// this.carToolType = list[0].value
						console.log(this.carToolType)
						// if(this.addCarForm.carToolType == ""){
						// 	this.addCarForm.carToolType = this.carToolList[0].fTransportToolTypeID
						// }
					},
					rej => {}
				);
			},
			//查询所有的车辆分组类别
			getgroupTypes() {
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/get-list-groupName', {}).then(
					result => {
						console.log('分组信息列表===================', result)
						this.carGroupList = result.obj.reslist;
						// this.carToolList.unshift('全部')
						// if(this.addCarForm.carToolType == ""){
						// 	this.addCarForm.carToolType = this.carGroupList[0]
						// }
					},
					rej => {}
				);
			},
			submitForm() {
				if (this.addCarForm.fMaximumLoad == 0) {
					this.tipsInfo("载货量不可为空")
					return;
				}
				// this.addCarLoading = true;
				this.$refs["addCarForm"].validate((valid) => {
					if (valid) {
						console.log(this.addCarForm.carGroupType)
						this.ApiRequestPost('api/mall/ebcustomer/transporttool/create', {
							fGroupName: this.addCarForm.carGroupType,
							fTransportToolTypeID: this.addCarForm.carToolType,
							fMaximumLoad: this.addCarForm.fMaximumLoad,
							fPlateNumber: this.addCarForm.fPlateNumber,
							fLinkMan: this.addCarForm.fLinkMan,
							fLinkManTelCode: this.addCarForm.fLinkManTelCode,
							fStatus: 0,
							fRemark: "SDDD"
						}).then(
							result => {
								console.log(result)
								// 派车车辆查询
								if (result) {
									// this.resetAddCarFrom();
									// this.getTransportgroupList()
									// this.getgroupTypes()
									// this.getgroupType()
									if (this.carToolType == '-1' || !this.carToolType) {
										this.getTransporttoolList()
										this.getgroupType()
									}
									this.getTransporttoolList(this.carToolType)
									this.getgroupType()
								}
								this.dialogAddCar = false;
								// this.addCarLoading = false;
							},
							rej => {
								this.dialogAddCar = false;
								// this.addCarLoading = false;
							}
						);
					} else {
						//console.log('error submit!!');
						this.carToolList3 = []
						return false;
					}
				});
			},
			// 车船号 小写字符转大写
			blurInput() {
				this.addCarForm.fPlateNumber = this.checkStrWord(this.addCarForm.fPlateNumber)
			},
			//特殊字符验证
			checkSpecialKey(str) {
				let specialKey = "[`~!@#$^&*()=|{}':;',\\[\\].·<>/?~！@#￥……&*（）——_|{}【】‘；：”“'。，、？-+/*<>《》]";
				for (let i = 0; i < str.length; i++) {
					if (specialKey.indexOf(str.substr(i, 1)) != -1) {
						return false;
					}
				}
				return true;
			},
			checkStrWord(str) {
				if (str != '' && str != null) {
					// 转字母大写
					str = str.toUpperCase();
					// 去除空格 弃用
					str = str.replace(/\s+/g, "");
					return str;
				}
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	/deep/ .el-dialog {
		/* background-image: url(../../../../assets/bgimg/dialog%20.png); */
		filter: alpha(opacity=70);
		background-repeat: no-repeat;
		background-size: 70% 18%;
		-moz-background-size: 70% 18%;
	}

	.el-dialog {
		margin-top: 15vh !important;
	}

	/deep/ .el-button--primary {
		border: none;
	}

	/deep/ .el-textarea__inner {
		border-radius: 0;
	}

	.top-box {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 10px 0;
	}

	.middle {
		height: calc(100vh - 556px);
	}

	.table-box {
		width: 100%;
		display: flex;
		justify-content: space-between;
		overflow-x: auto;
		margin: 10px 0;
	}

	.flex-center {
		width: 320px;
		margin: auto;
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin-bottom: 20px;
	}

	.carname p {
		font-size: 14px;
		color: #000000;
		margin: 5px 5px 5px 20px;
	}

	.weight p {
		font-size: 12px;
		color: #999999;
		margin-left: 20px;
	}

	.carInfor {
		border-bottom: 1px solid #F2F2F2;
		padding-bottom: 8px;
	}

	.list-radio {
		position: relative;
	}

	.rowlist-radio {
		position: absolute;
		left: 0;
		top: 20px;
	}


	.carInfor1 {
		// width: calc(45% - 9px);
		// width: -webkit-calc(45% - 9px);
		// width: -moz-calc(45% - 9px);
		width: 350px;
		height: 73px;
		box-sizing: border-box;
		// display: inline-block;
		background-color: #fff;
		padding: 15px 16px;
		border-radius: 10px;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
		margin-bottom: 15px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		float: left;
		margin-right: 16px;
	}

	.carnamespan {
		min-width: 67px;
		margin-right: 10px;
	}

	.carname1 {
		color: #000;
		font-weight: bold;
		font-size: 14px;
	}

	.carname1 span,
	.weight1 {
		display: block;
	}

	.carname1 span {
		margin-right: 10px;
		vertical-align: middle;
	}

	.weight1 {
		font-size: 12px;
		color: #666;
	}

	.editicon .u-icon:last-child {
		margin-left: 10px;
		margin-right: -2.5px;
	}

	.cartype1 {
		display: inline-block;
		width: 60px;
		color: #0173FE;
		font-size: 12px;
		font-weight: 400;
	}

	.editIconBtn {
		margin-right: 22px;
	}

	.flex-row {
		display: flex;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.pointer-color-theme:hover {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.btn-box {
		width: 160px;
		height: 40px;
		line-height: 40px;
	}


	.btn-box1 {
		width: 120px;
		height: 40px;
		line-height: 40px;
	}

	.send-car-item {
		width: 500px;
		// height: 30px;
		border: 1px solid #f2f2f2;
		border-radius: 5px;
		padding: 10px;
	}

	.send-car-item-fill {
		width: 362px;
		height: 76px;
	}

	.width100 {
		width: 100%;
	}

	.el-input ::v-deep .el-input-group__append,
	.el-input-group__prepend {
		padding: 0;
	}

	.add-car-btn {
		min-width: 110px;
		height: 30px;
		border-radius: 4px;
	}

	.padding-6 {
		padding: 6px 0;
	}

	.flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.el-input {
			width: 130px;
		}
	}

	.table-btn {
		width: 60px;
		height: 30px;
	}

	::v-deep .el-form-item .el-input__inner {
		width: 380px;
	}

	.input-x .el-input__inner {
		height: 23px;
	}

	.Sendcar-box {
		height: 100%;
		width: 100%;
		max-width: 1100px;
		min-width: 1100px;
		margin: 0 auto;
	}

	::v-deep .el-input__inner {
		height: 32px;
		line-height: 20px;
	}

	::v-deep .el-dialog__body {
		padding: 0px 20px;
	}

	::v-deep .el-input__icon {
		line-height: 32px;
	}

	::v-deep .el-button {
		padding: 8px 12px;
	}

	::v-deep .el-table .cell {
		padding-right: 10px;
	}

	::v-deep th {
		font-weight: 500;
		color: #000000;
	}

	::v-deep element.style {
		width: 100%;
		margin-top: 0px;
	}

	::v-deep .el-table .cell {
		padding-right: 0px;
	}

	::v-deep.el-table .cell,
	.el-table--border td:first-child .cell,
	.el-table--border th:first-child .cell {
		padding-left: 0px;
	}

	::v-deep .el-table td,
	::v-deep .el-table th {
		padding: 5px 0px;
		text-align: center;
	}

	.el-table th,
	.el-table tr {
		background-color: #CCCCCC;
	}

	.el-table thead {
		color: #000000;
		// font-weight: 500;
	}

	.SendCar-box1 {
		margin-top: 10px;
	}

	.SendCar-title {
		width: 505px;
		height: 107px;
		// background: linear-gradient(45deg, #E8FDFF, #EAEFFF, #E8FDFF, );
		background: url(../../assets/sendCar/pcbg.png) 100% 100% no-repeat;
		background-size: contain;
		// float: left;
		// padding-left: 15px;
	}

	.SendCar-title p:first-child {
		font-size: 14px;
		font-weight: 400;
		color: #000000;
	}

	.SendCar-title p:last-of-type {
		font-size: 22px;
		font-weight: bold;
		margin-left: 36px;
		margin-top: 5px;
	}

	.width-120 {
		width: 160px;
	}

	.Sendcar-right {
		width: 505px;
		height: 107px;
		// border: 1px solid rgba(242, 242, 242, 1);
		background: url(../../assets/sendCar/pcbg1.png) 100% 100% no-repeat;
		background-size: contain;
		float: right;
	}

	.width-505 {
		width: 505px;
	}

	.deletIconBtn {
		font-size: 16px;
		font-color: #000;
		font-weight: bolder;
	}

	.Sendcar-right p:first-child {
		color: #666666;
		margin: 15px 0px 7px 15px;
	}

	.Sendcar-right p:nth-child(2) {
		font-size: 14px;
		color: #000000;
		margin: 0px 0px 0px 41px;
		line-height: 24px;
	}

	.Sendcar-right p:last-of-type {
		font-size: 14px;
		color: #000000;
		margin-left: 41px;
	}

	.SensCar-check {
		font-size: 15px;
		font-weight: bold;
		// padding:23px 0px 9px 0px ;
		// margin-top:23px;
		line-height: 35px;
		margin-left: 13px;
	}

	.sendcar-bottom {
		width: 1100px;
		// height: 290px;
		// border: 1px solid rgba(242, 242, 242, 1);
	}

	.sendcar-conone {
		border: 1px solid rgba(242, 242, 242, 1);
		height: 290px;
	}

	.sendcar-conone img {
		margin-left: 451px;
	}

	.sendcar-conone p {
		font-size: 15px;
		// font-weight: bold;
		color: #cccccc;
		text-align: center;
	}

	.sendcar-conone span {
		color: #0173FE;
		border-bottom: 1px solid #0173FE;
	}

	.Sendcar-footer,
	.Sendcar-footerTwo {
		// width: 1099px;
		height: 69px;
		background: #f8f9fe;
		margin-top: 10px;
	}

	.Sendcar-footer div {
		width: 120px;
		height: 40px;
		background-color: #2E66F8;
		color: #FFFFFF;
		float: right;
		margin: 15px 20px 0px 0px;
		text-align: center;
		line-height: 40px;
	}

	.carname,
	.weight {
		display: flex;
	}

	.Sendcar-footerTwo {
		display: flex;
	}
</style>
